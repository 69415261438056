import firebase from "firebase";
import "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import "firebase/messaging";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCu38D64_x9qZkdqooQ8lyTUXPSCYrfeQA",
  authDomain: "auctioonapp.firebaseapp.com",
  projectId: "auctioonapp",
  storageBucket: "auctioonapp.appspot.com",
  messagingSenderId: "977811779422",
  appId: "1:977811779422:web:7637120babbc98b62929cf",
  measurementId: "G-3K7LGHDGCM"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();



  export default firebase;

  