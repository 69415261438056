import React from "react";
import { withRouter } from "react-router-dom";

import firebase from "../utils/firebaseSetUp";

import logo from "../res/logo/logo-auctioon.png"

import $ from "jquery";

class Navbar extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            email:"",
            pass:"",
            isLoading:false,
            forget:false,
        }
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }


    bilanguage = (es,en) => {
        return this.props.lng === "es"?es:en
    } 


    login =() => {

        if(this._mounted){
            this.setState({
                isLoading:true
            })
        }

        firebase.auth().signInWithEmailAndPassword(this.state.email.trim().toLowerCase(), this.state.pass.trim())
        .then(() => {

            if(this._mounted){
                this.setState({
                    isLoading:false,
                })
            }

        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");

            if(this._mounted){
                this.setState({
                    isLoading:false,
                })
            }
        })
    }

    render(){
        return <div className="main-nav-bar mb-3 flex-sb">

            <div>
            <div className="flex-sb main-inner-nav-bar">
            <div className="logo" onClick={() => {
                this.props.history.push("/")
            }} style={{
                backgroundImage:`url(${logo})`,
                backgroundPosition:"center",
                backgroundRepeat:"no-repeat",
                backgroundSize:"contain"
            }}></div>

            {this.props.auth === false?<div className="sign-in flex-end">
                {this.state.forget === false?<><input className="form-control form-control-1 mr-2" disabled={this.state.isLoading} value={this.state.email} placeholder="Email" onChange={e => {
                    if(this._mounted){
                        this.setState({
                            email:e.currentTarget.value
                        })
                    }
                }} />

                <input className="form-control form-control-1 mr-2" disabled={this.state.isLoading} value={this.state.pass} type="password" placeholder="Contraseña" onChange={e => {
                    if(this._mounted){
                        this.setState({
                            pass:e.currentTarget.value
                        })
                    }
                }}/></>:<input className="form-control form-control-1 mr-2" disabled={this.state.isLoading} value={this.state.email} placeholder="Email" onChange={e => {
                    if(this._mounted){
                        this.setState({
                            email:e.currentTarget.value
                        })
                    }
                }} />}

                {this.state.isLoading?<div>
                    <div className="spinner-border"></div>
                </div>:this.state.forget === false?<button className="btn btn-custom-1 btn-sm" onClick={() => {
                    this.login();
                }}>
                    <span>{this.bilanguage("Ingresar","Login")}</span>
                </button>:<button className="btn btn-custom-1 btn-sm" onClick={async() => {
               $.ajax({url:"https://cuidabu.herokuapp.com/recover-password",
                    crossDomain:true,
                                                     data:{
                                                       email:this.state.email
                                                       },
                                                method:"POST",
                                                })
                                                .then(r => {
                                                    this.props.addToast("OK")
                                                })
                                                .catch(e => {

                                                })
                }}>
                    <span>{this.bilanguage("Recuperar","Recovery")}</span>
                </button>}
            </div>:null}

            {this.props.auth === true?<div className="welcome flex-end-2">

                <div>
                <h4 className="mr-4">{this.bilanguage("Hola","Hi")}, {this.props.userData.firstName}</h4>
                <p className="mb-0">(@{this.props.userData.username})</p>
                </div>

                {this.props.userData.type !== "admin"?<h4 className="mr-4">{this.bilanguage("Pujas","Bids")}: <span className="bids">{this.props.userData.bids}</span></h4>:null}

                {this.props.isLoading === false?<button className="btn btn-light btn-sm" onClick={() => {
                    firebase.auth().signOut();
                }}>
                    <span>{this.bilanguage("Salir","Logout")}</span>
                </button>:<div>
                    <div className="spinner-border"></div>
                    </div>}
            </div>:null}
            </div>

            {this.props.auth === false?<div className="main-inner-nav-bar flex-sb pt-0">
                <div></div>

                <div className="flex-end">
                    {this.state.forget === false?<p className="mb-0 mr-2">
                        {this.bilanguage("Olvidaste tu Contraseña?","Did you forget your password?")} <a className="" href="#" onClick={e => {
                            e.preventDefault();

                            if(this._mounted){
                                this.setState({
                                    forget:true
                                })
                            }
                        }}>{this.bilanguage("Click Aquí","Click here")}</a>
                    </p>:<p className="mb-0 mr-2">
                        {this.bilanguage("Ingresar","Sign In")} <a className="" href="#" onClick={e => {
                            e.preventDefault();

                            if(this._mounted){
                                this.setState({
                                    forget:false
                                })
                            }
                        }}>{this.bilanguage("Click Aquí","Click Here")}</a>
                    </p>}

                    <p className="mb-0">
                        {this.bilanguage("No eres miembro?","Not a member?")} <a className="" href="#" onClick={() => {
                            this.props.history.push("/registro")
                        }}>{this.bilanguage("Regístrate Ahora","Register now")}</a>
                    </p>
                </div>
            </div>:null}

            

            </div>


        </div>
    }
}

export default withRouter(Navbar);