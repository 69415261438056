import logo from './logo.svg';
import './styles/sass/App.scss';
import React, { lazy, Suspense } from 'react';

import {BrowserRouter as Router, Switch, NavLink, Route, Redirect} from "react-router-dom";

import { Position, Toast, Toaster, Classes, Alert, Intent} from "@blueprintjs/core";

import CircleLoader from './components/loaders/CircleLoader';

import Navbar from './components/Navbar';

import Footer from './components/Footer';

import firebase from "./utils/firebaseSetUp"

import $ from "jquery";



const Home = lazy(() => import("./components/Home"));
const Register = lazy(() => import("./components/Register"));
const SignIn  = lazy(() => import("./components/SignIn"))
const MyAccount = lazy(() => import("./components/MyAccount"));

const Dashboard = lazy(() => import("./components/admin/Dashboard"));

const ViewAuction = lazy(() => import("./components/ViewAuction"));

const SuccessBuy = lazy(() => import("./components/SuccessBuy"))

const FailBuy = lazy(() => import("./components/FailBuy"));

const FilterAuctions = lazy(() => import("./components/FilterAcutions"));

const Contact = lazy(() => import("./components/Contact"));

const Help = lazy(() => import("./components/Help"))

class App extends React.Component {
  _mounted;
  _authListener;
  _listenUser;
  _listenGlobalParameters;

  constructor(props){
    super(props);

    this.state = {
      toasts:[],
      signingUp:false,
      auth:false,
      userData:null,
      isLoading:false,
      inviteCode:"",
      globalParameters:null,
      exchange:"",
      city:"",
      countryName:"",

      alert:{
        isOpen:false,
        intent:"",
        text:"",
        icon:"issue",
        success:() => {

        }
      },

      selectedCategory:"",

      lng:"es"
    }

    this.toaster = {};
    this.refHandlers = {
        toaster:(ref) => {this.toaster = ref},
    }
  }

  mountGlobalParameters = () => {

    if(this._listenGlobalParameters !== undefined){
      this._listenGlobalParameters(); //Clean up the listener
    }
    this._listenGlobalParameters = firebase.firestore().collection("globalParameters").doc("parameters").onSnapshot(doc => {
      let data= doc.data();
      let id= doc.id;

      if(this._mounted){
        this.setState({
          globalParameters:data
        })
      }
    }, e => {
      console.log(e);
      this.addToast("Algo salió mal")
    })
  }

  mountUser = (id) => {
    this._listenUser = firebase.firestore().collection("users").doc(id).onSnapshot(doc => {
      let data =doc.data();
      data.id = doc.id;

      if(this._mounted){
        this.setState({
          userData:data
        })
      }
    }, e => {
      console.log(e);
      this.addToast("Algo salió mal")
    })
  }


  login = (email,pass) => {
     if(this._mounted){
       this.setState({
         isLoading:true
       })
     }

     firebase.auth().signOut()
     .then(() => {

      
     firebase.auth().signInWithEmailAndPassword(email, pass)
     .then(() => {

      if(this._mounted){
        this.setState({
          isLoading:false,
        })
      }

      
     })
     .catch(e => {
       console.log(e);
       this.addToast("Algo salió mal");

       if(this._mounted){
         this.setState({
           isLoading:false
         })
       }
     })
     })
     .catch(e => {
       console.log(e);
       this.addToast("Algo salió mal")
     })

  }

  getExchange = (code) => {
    $.ajax({url:`https://eodhistoricaldata.com/api/real-time/${code}.FOREX?api_token=5d8fa38ea980d4.88556729&fmt=json`})
    .then(r => {

      if(r.close === "NA"){
        if(this._mounted){
          this.setState({
            exchange:"NA"
          }, () => {
            console.log(this.state.exchange)
          })
        }
      }else {
        if(this._mounted){
          this.setState({
            exchange:r.close
          }, () => {
            console.log(this.state.exchange)
          })
        }
      }
    })
    .catch(e => {
      console.log(e);
      
    }) 
  }

  componentDidMount(){
    this._mounted = true;

    $.ajax({url:"http://api.ipstack.com/190.120.253.131?access_key=228e7f466515ebc43a96448a75d6e641&format=1"})
    .then(r => {
      console.log(r)

      if(this._mounted){
        this.setState({
          city:r.city,
          countryName:r["country_name"]
        })
      }
    })
    .catch(e => {
      console.log(e);
      
    })

    var lng = window.navigator.userLanguage || window.navigator.language;

    console.log(lng)

    if(String(lng).includes("es")){

    }else {
      if(this._mounted){
        this.setState({
          lng:"en"
        })
      }
    }

    let params = (new URL(document.location)).searchParams;
    let code = params.get("code");

    if(code){
      if(this._mounted){
        this.setState({
          inviteCode:code
        })
      }
    }

    firebase.auth().onAuthStateChanged(u => {

      if(this._mounted){
        this.setState({
        isLoading:true
        })
      }
      if(u){

        if(this.state.signingUp === false){

          firebase.firestore().collection("users").doc(u.uid).get()
          .then(doc => {

            if(doc.exists){
              let data = doc.data();
              data.id = doc.id;

              this.getExchange(data.currency)

              if(this._mounted){
                this.setState({
                  auth:true,
                  userData:data
                }, () => {
                  this.mountUser(doc.id);

                  this.mountGlobalParameters()
                })
              }

              if(this._mounted){
                this.setState({
                  isLoading:false
                })
              }
            }else {
              firebase.auth().signOut()

          
            }
          })
          .catch(e => {
            console.log(e);
            this.addToast("Algo salió mal")

            if(this._mounted){
              this.setState({
                isLoading:false,
              })
            }
          })
        }else {
          if(this._mounted){
            this.setState({
              isLoading:false,
            })
          }
        }
      }else {

        if(this._mounted){
          this.setState({
            auth:false,
            userData:null,
            isLoading:false,
          })
        }

        if(this._listenUser !== undefined){
          this._listenUser(); //Clean up the listener
        }
      }
    })
  }

  componentWillUnmount(){
    this._mounted = false;

    if(this._listenGlobalParameters !== undefined){
      this._listenGlobalParameters(); //Clean up the listener
    }
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}

  openAlert = (state, text, icon, intent, cb) => {
    
    if(this._mounted){
      this.setState({
        alert:{
          isOpen:state,
          text:text,
          icon:icon,
          intent:intent,
          success:cb
        }
      })
    }
  }

  render(){
    return <div className="app">
         <Alert 
      isOpen={this.state.alert.isOpen}
      icon={this.state.alert.icon}
      confirmButtonText="Si"
      cancelButtonText="No"
      intent={this.state.alert.intent}
      onConfirm={() => {
        this.state.alert.success(() => {
          let base = Object.assign({}, this.state.alert);
          base.isOpen = false;
          if(this._mounted){
            this.setState({
              alert:base
            })
          }
        })
      }}
      onCancel={() => {
        let base = Object.assign({}, this.state.alert);
        base.isOpen = false;
        if(this._mounted){
          this.setState({
            alert:base
          })
        }
      }}
      >
        <p>
          {this.state.alert.text}
         </p>
      </Alert>
       <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
                </Toaster>
        
      
       <Suspense fallback={<CircleLoader/>}>
         <Router>
        <Navbar lng={this.state.lng} isLoading={this.state.isLoading} globalParameters={this.state.globalParameters}  auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>
           <Switch>
             <Route exact={true} path="/" render={() => <Home countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} selectCategory={c => {
              if(this._mounted){
                this.setState({
                  selectCategory:c
                })
              }
            }} auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>}/>
             <Route exact={true} path="/registro" render={() => this.state.auth === false?<Register countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} auth={this.state.auth} userData={this.state.userData} login={(e,p) => {
               this.login(e,p)
             }} signingUp={(d) => {
               if(this._mounted){
                 this.setState({
                   signingUp:d
                 })
               }
             }} addToast={this.addToast}/>:<Redirect to="/"/>}/>
             <Route exact={true} path="/ingresar" render={() => this.state.auth === false?<SignIn countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>:<Redirect to="/"/>}/>

             <Route exact={true} path="/mi-cuenta" render={() => this.state.auth?<MyAccount countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>:<Redirect to="/"/>}/>

             <Route exact={true} path="/admin" render={() => <Dashboard countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} userData={this.state.userData} auth={this.state.auth} addToast={this.addToast}/>}/>

             <Route exact={true} path="/subasta/:auctionID" render={({match}) => <ViewAuction countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} match={match} addToast={this.addToast} userData={this.state.userData}/>}/>

             <Route exact={true} path="/exito-compra" render={() => <SuccessBuy countryName={this.state.countryName}  city={this.state.city} exchange={this.state.exchange}lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters}/>}/>

             <Route exact={true} path="/error" render={() => <FailBuy countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters}/>}/>

             <Route exact={true} path="/subastas" render={() => <FilterAuctions
             countryName={this.state.countryName}
             city={this.state.city} exchange={this.state.exchange}
             lng={this.state.lng}
             selectedCategory={this.state.selectedCategory}
              selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} addToast={this.addToast} userData={this.state.userData}/>}/>


             <Route exact={true} path="/contacto" render={() => <Contact countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng}  selectedCategory={this.state.selectedCategory}
              selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} addToast={this.addToast} userData={this.state.userData}/>}/>

             <Route exact={true} path="/ayuda" render={() => <Help countryName={this.state.countryName} city={this.state.city} exchange={this.state.exchange} lng={this.state.lng} selectedCategory={this.state.selectedCategory}
              selectCategory={c => {
               if(this._mounted){
                 this.setState({
                   selectedCategory:c
                 })
               }
             }} globalParameters={this.state.globalParameters} inviteCode={this.state.inviteCode} addToast={this.addToast} userData={this.state.userData}/>} />
           </Switch>

           <Footer lng={this.state.lng}/>
         </Router>
       </Suspense>

      
    </div>
  }
}

export default App;
