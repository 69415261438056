import React from "react";
import { withRouter } from "react-router";

class Footer extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;

    }

    bilanguage = (es,en) => {
        return this.props.lng === "es"?es:en
    } 

    render(){
        return <div className="footer flex-center mt-2">
            <div id="footerSection">
                        <div class="newsQuotes row mx-0">

                            <div className="col-sm-4 flex-center">
                            <div class="quote">
                                <img src="https://www.orangebidz.com/site/tmpl/registration/images/wired.png" alt=""/>
                                <p><img src="https://www.orangebidz.com/site/tmpl/registration/images/left-quote-medium.png" alt=""/>{this.bilanguage("Vivir en una gran vivienda está al alcance de todo el mundo, gracias a Inmoauction se pueden conseguir viviendas de alquiler por 1 dólar","Living in a large house is available to everyone, thanks to Inmoauction you can get rental housing for $ 1")}<img style={{verticalAlign:"middle"}} src="https://www.orangebidz.com/site/tmpl/registration/images/right-quote-medium.png" alt=""/></p>
                            </div>
                            </div>

                            <div className="col-sm-4 flex-center">
                            <div class="quote">
                                <img src="https://www.orangebidz.com/site/tmpl/registration/images/time-magazine.png" alt=""/>
                                <p><img src="https://www.orangebidz.com/site/tmpl/registration/images/left-quote-medium.png" alt=""/>{this.bilanguage("Una gran oportunidad para todo aquel que no se pueda permitir gastar una gran suma para comprar una vivienda, con menos de 100$ se puede conseguir ganar viviendas que antes no estaban al alcance de muchos.","A great opportunity for anyone who cannot afford to spend a large sum to buy a home, with less than $ 100 you can win homes that were previously not available to many.")}<img  style={{verticalAlign:"middle"}} src="https://www.orangebidz.com/site/tmpl/registration/images/right-quote-medium.png" alt=""/></p>
                            </div>
                            </div>

                            <div className='col-sm-4 flex-center'> 
                            <div class="quote">
                                <img src="https://www.orangebidz.com/site/tmpl/registration/images/the-wall-street.png" alt=""/>
                                <p><img src="https://www.orangebidz.com/site/tmpl/registration/images/left-quote-medium.png" alt=""/>{this.bilanguage("La alternativa que muchos jovenes necesitan para poder tener una vivienda digna en propiedad o alquiler.","The alternative that many young people need in order to have a decent home to own or rent.")}<img style={{verticalAlign:"middle"}} src="https://www.orangebidz.com/site/tmpl/registration/images/right-quote-medium.png" alt=""/></p>
                            </div>
                             </div>

                            <div class="clear"></div>
                        </div>

                        <p class="featuredIn text-center">{this.bilanguage("Nuestra plataforma de subastas ha aparecido en:","Our platform has been featured on:")}</p>

                        <div class="footerDivider"></div>

                        <div class="mediaIcons">
                            <img src="https://www.orangebidz.com/site/tmpl/registration/images/footer-media.jpg" alt=""/>
                        </div>

                        <p class="copyright">Emiigrar.com</p>
                    </div>
        </div>
    }
}

export default withRouter(Footer);